import classes from "./Controls.module.scss";

const BackButton = props => {
  return (
    <button
      type="reset"
      className={ classes.resetBtn }
      onClick={ props.onClick }
    >{ props.text }
    </button>
  );
}

export default BackButton;