import {Fragment} from "react";
import OralSectionItem from "./OralSectionItem";

const OralSectionList = props => {

  const generateOralSectionItems = exams => {
    const resultList = [];
    exams.forEach( ( exam, i ) => {
      resultList.push(
        <OralSectionItem
          exam = { exam }
          index = { i }
          key = { `oralExam_${ i }` }
        />
      );
    });
    return resultList;
  }

  return (
    <Fragment>
      { generateOralSectionItems( props.exams ) }
    </Fragment>
  );
}

export default OralSectionList
