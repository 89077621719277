const ExamPlanTableHeader = () => {
  return (
    <thead>
      <tr>
        <td>von</td>
        <td>bis</td>
        <td>Raum</td>
        <td>Aktivität</td>
        <td>verantwortlich</td>
      </tr>
    </thead>
  );
}

export default ExamPlanTableHeader;