import React, {useState} from 'react';
import classes from "./TimeProfileBox.module.scss";

const TimeProfileBox = props => {
  const addZeroIfLessTen = value => ( value < 10 ) ? "0" + value : +value;

  const [ showConfigBar, setShowConfigBar ] = useState( false );
  const [ startAtHours, setStartAtHours ] = useState( addZeroIfLessTen( props.startingHour ) );
  const [ startAtMinutes, setStartAtMinutes ] = useState( addZeroIfLessTen( props.startingMinutes ) );

  const resetOnClickHandler = event => {
    event.preventDefault();
    setShowConfigBar( true );
  }

  // list of profiles available
  const profileList = props.availableProfiles.map( ( profile, i ) =>
    <option key={ "profile_" + i } value={ profile.type } >{ profile.name }</option> );

  // if the user changes the settings Profile
  const timeProfileChangeHandler = event => {
    const selectedOption = event.target.options.selectedIndex;
    const selectedOptionValue = event.target.options[ selectedOption ].value;
    const settingsProfile = props.profiles.find( profile => profile.id === selectedOptionValue );
    props.onProfileChangeHandler( settingsProfile );

    if ( showConfigBar ) {
      const hours = addZeroIfLessTen( settingsProfile.written.defaultStartTime.getHours() );
      const minutes = addZeroIfLessTen( settingsProfile.written.defaultStartTime.getMinutes() );
      setStartAtHours( hours );
      setStartAtMinutes( minutes );
    }
  }

  const onHoursChangeHandler = event => {
    props.onHoursMinChangeHandler( parseInt( event.target.value ), true );
    setStartAtHours( addZeroIfLessTen( event.target.value ) );
  }

  const onMinutesChangeHandler = event => {
    props.onHoursMinChangeHandler( parseInt( event.target.value ), false );
    setStartAtMinutes( addZeroIfLessTen( event.target.value ) );
  }

  return (
    <div className={ classes.timeProfileBox }>
      <label htmlFor="timeProfileSelector">ZeitProfil: </label>
      <select
        name="timeProfile"
        id="timeProfileSelector"
        className={ classes.timeProfileSelector }
        onChange={ timeProfileChangeHandler }
      >
        { profileList }
      </select>

      {/* Show Configuration Bar if needed */}

      { showConfigBar
        ?
          <div className={ classes.configBar }>
            <span>Anfangen um </span>
            <input
              type="number"
              size="2"
              min="00"
              max="23"
              step="1"
              className={ classes.configBarTimeInput }
              value={ startAtHours }
              onChange={ onHoursChangeHandler }
              onFocus={ event => event.target.select() }
            />
            <span> : </span>
            <input
              type="number"
              size="2"
              min="00"
              max="50"
              step="10"
              className={ classes.configBarTimeInput }
              value={ startAtMinutes }
              onChange={ onMinutesChangeHandler }
              onFocus={ event => event.target.select() }
              />
          </div>
        :
          <button
            type="reset"
            className={ classes.setupBtn }
            onClick={ resetOnClickHandler }
          >anpassen</button>
      }
    </div>
  );
};

export default TimeProfileBox;
