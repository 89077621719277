import {Fragment, useContext} from "react";
import AppSettings from "../../../store/appSettings";
import WrittenInput from "./UI/WrittenInput";
import OralInput from "./UI/OralInput";
import classes from "./_GetExamInfoItem.module.scss";

const GetExamInfoItem = props => {
  const appSettings = useContext( AppSettings );

  const writtenParticipants = props.exam.written.participants;

  const currentSettingsProfile = appSettings.profiles.filter( prof => prof.id === props.timeProfile )[0];

  const maxParticipantsPerWrittenGroup = currentSettingsProfile.written.maxAmountOfParticipantsPerGroup;
  const writtenGroups = (( writtenParticipants / maxParticipantsPerWrittenGroup ) >> 0 ) + 1;

  const buildWrittenInputList = ( writtenGroups, exam ) => {
    const list = [];
    for (let i = 0; i < writtenGroups; i++) {
      list.push(
        <WrittenInput
          groupNumber = { i + 1 }
          exam = { exam }
          key = { exam.type.name + "_" + i }
        />
      );
    }
    return list;
  }

  return (
    <Fragment>
      {/*<h1> { props.number }. { props.exam.type.name } </h1>*/}
      <fieldset className={ classes.examItem }>
        <legend className={ classes.examItemLegend } >{ props.exam.type.name } </legend>

        {/* schriftlich */}
        { buildWrittenInputList( writtenGroups, props.exam ) }

        {/* mündlich */}
        <OralInput
          exam = { props.exam }
        />

      </fieldset>
    </Fragment>
  );
}

export default GetExamInfoItem;