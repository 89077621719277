import classes from "./_ControlBoxJustified.module.scss";

const ControlBoxJustified = props => {
  return(
    <div className = { classes.controlBox }>
      <div className = { classes.left }>
        { props.left }
      </div>
      <div className = { classes.right }>
        { props.right }
      </div>
    </div>
  );
}

export default ControlBoxJustified;