import {Fragment} from "react";
import {printTimeHHMM} from "../../../../helpers/lib";

const WrittenSection = props => {
  const exams = props.exams;
  const writtenInfoBlock = [];
  let exam, startTime, endTime, examName, examWritten;

  const printMultipleValuesForGroups = ( exam, keyTemplate ) => {
    let resultString, key, prefix;
    resultString = [];
    key = "";
    if ( exam.written.numOfGroups > 0 ) {
      for ( let i = 1; i <= exam.written.numOfGroups; i++ ) {
        key = `${ keyTemplate }${ i }`;
        if ( exam.written[ key ] !== undefined ) {
          prefix = ( exam.written.numOfGroups > 1 ) ? `Gruppe ${ i } :` : ""
          resultString.push( `${ prefix } ${ exam.written[ key ] }` );
          resultString.push( <br/> );
        }
      }
    }
    return resultString;
  }

  for ( let i = 0; i < exams.length; i++ ) {
    exam = exams[i];
    examWritten = exam.written;
    examName = exam.type.shortName;
    startTime =  examWritten.start;
    endTime   =  examWritten.end;

    writtenInfoBlock.push(
      <tr key={ `written_${ i }`  } >
        <td> { printTimeHHMM( startTime ) } </td>
        <td> { printTimeHHMM( endTime ) } </td>
        <td> { printMultipleValuesForGroups( exam, "room_" ) } </td>
        <td> { examName.toUpperCase() } schriftlich </td>
        <td> { printMultipleValuesForGroups( exam, "supervisor_" ) } </td>
      </tr>
    );
  }

  return (
    <Fragment>
      { writtenInfoBlock.map( item => item ) }
    </Fragment>
  );
}

export default WrittenSection;