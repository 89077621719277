import {useState} from "react";
import classes from "./_WrittenOralnput.module.scss";

const OralInput = props => {
  const [ supervisor, setSupervisor ] = useState("");
  const [ waitingRoom, setWaitingRoom ] = useState("0");
  const [ preparationRoom, setPreparationRoom ] = useState("0");
  const [ examRoom, setExamRoom ] = useState("0");
  const [ firstExaminer, setFirstExaminer ] = useState("");
  const [ secondExaminer, setSecondExaminer ] = useState("");

  const exam = props.exam.oral;

  const onChangeSupervisorHandler = ( event ) => {
    setSupervisor( event.target.value );
    exam[ "supervisor" ] = event.target.value;
  };

  const onChangeWaitingRoomHandler = ( event ) => {
    const newValue = event.target.value;
    setWaitingRoom( newValue );
    exam[ "waitingRoom" ] = newValue;
  };

  const onChangePreparationRoomHandler = ( event ) => {
    setPreparationRoom( event.target.value );
    exam[ "preparationRoom" ] = preparationRoom;
  };

  const onChangeExamRoomHandler = ( event ) => {
    setExamRoom( event.target.value );
    exam[ "examRoom" ] = examRoom;
  };

  const onChangeFirstExaminerHandler = ( event ) => {
    setFirstExaminer( event.target.value );
    exam[ "firstExaminer" ] = firstExaminer;
  };

  const onChangeSecondExaminerHandler = ( event ) => {
    setSecondExaminer( event.target.value );
    exam[ "secondExaminer" ] = secondExaminer;
  };

  return (
    <fieldset className={ classes.oralBlock }>
      <legend>Mündlich</legend>

      {/* Prüfer 1 */}
      <fieldset>
        <label htmlFor="preparationRoom">Prüfer 1  : </label>
        <input type="text" value={ firstExaminer } name="preparationRoom" size="30" onChange={ onChangeFirstExaminerHandler }/>
      {/*</fieldset>*/}

      {/* Prüfer 2 */}
      {/*<fieldset>*/}
        <label htmlFor="secondExaminer">Prüfer 2 : </label>
        <input type="text" value={ secondExaminer } name="secondExaminer" size="30" onChange={ onChangeSecondExaminerHandler }/>
      </fieldset>


      {/* Aufsicht */}
      <fieldset>
        <label htmlFor="supervisor">Aufsicht : </label>
        <input type="text" value={ supervisor } name="supervisor" size="30" onChange={ onChangeSupervisorHandler }/>
      </fieldset>



      {/* Warteraum */}
      <fieldset>
        <label htmlFor="waitingRoom">Warteraum : </label>
        <input type="text" value={ waitingRoom } name="waitingRoom" size="3" onChange={ onChangeWaitingRoomHandler }/>
      {/*</fieldset>*/}

      {/* Vorbereitungsraum */}
      {/*<fieldset>*/}
        <label htmlFor="preparationRoom">Vorbereitungsraum : </label>
        <input type="text" value={ preparationRoom } name="preparationRoom" size="3" onChange={ onChangePreparationRoomHandler }/>
      {/*</fieldset>*/}

      {/* Prüfungsraum */}
      {/*<fieldset>*/}
        <label htmlFor="preparationRoom">Prüfungsraum : </label>
        <input type="text" value={ examRoom } name="preparationRoom" size="3" onChange={ onChangeExamRoomHandler }/>
      </fieldset>


    </fieldset>
  );
}

export default OralInput;
