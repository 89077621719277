import classes from "./_ControlBoxCentered.module.scss";

const ControlBoxCentered = props => {
  return(
    <div className = { classes.controlBox }>
      { props.children }
    </div>
  );
}

export default ControlBoxCentered;