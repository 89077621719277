import classes from "./Controls.module.scss";

const ConfirmButton = props => {
  return (
    <button
      type="submit"
      className = { classes.calculateBtn }
      onClick  =  { props.onClick }
      disabled =  { props.disabled }
    >{ props.text }</button>
  );
}

export default ConfirmButton;