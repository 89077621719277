import React from "react";

const TelcDeutschExamSettings = React.createContext({
  // list of available telc exams in this file
  availableExams: [
    { type: "none", name: "keine" },
    { type: "a1",   name: "telc Deutsch A1" },
    { type: "a2",   name: "telc Deutsch A2" },
    { type: "b1",   name: "telc Deutsch B1" },
    { type: "b2",   name: "telc Deutsch B2" },
    { type: "b12p",   name: "telc D. B1-B2 Pflege" },
    { type: "c1h",   name: "telc D. C1 Hoch" },
  ],

  subtestGeneralInfo: {
    reading: "Lesen & Sprachbausteine",
    writtenBreak: "Pause",
    listening: "Hören",
    writing: "Schreiben",
    preparation: "Vorbereitung",
    oralExam: "Sprechen",
  },

  // telc Deutsch A1
  a1 : {
    name: "telc Deutsch A1",
    shortName: "a1",
    written: {
      durationMin: 65, // Minutes
    },
    oral: {
      participantsPerSession: 4,
      plusOneForSpecial: false,
      examinationTimeMin: {
        1: 10,
        2: 15,
        3: 18,
        4: 20
      },
      preparationTimeMin: 0,
      evaluationTimeMin: {
        1: 2,
        2: 3,
        3: 4,
        4: 5
      },
    },
  },

  // telc Deutsch A2
  a2 : {
    name: "telc Deutsch A2",
    shortName: "a2",
    written: {
      durationMin: 70, // Minutes
    },
    oral: {
      participantsPerSession: 4, // copied from A1
      plusOneForSpecial: false,
      examinationTimeMin: {
        1: 10,
        2: 15,
        3: 18,
        4: 20
      },
      preparationTimeMin: 0,
      evaluationTimeMin: {
        1: 2,
        2: 3,
        3: 4,
        4: 5
      },
    },
  },

  // telc Deutsch B1
  b1 : {
    name: "telc Deutsch B1",
    shortName: "b1",
    written: {
      durationMin: 150, // Minutes
    },
    oral: {
      participantsPerSession: 2,
      plusOneForSpecial: false,
      examinationTimeMin: {
        1: 10,
        2: 15,
      },
      preparationTimeMin: 20,
      evaluationTimeMin: {
        1: 3,
        2: 5,
      },
    }
  },

  // telc Deutsch B2
  b2 : {
    name: "telc Deutsch B2",
    shortName: "b2",
    written: {
      durationMin: 140, // Minutes
    },
    oral: {
      participantsPerSession: 2,
      plusOneForSpecial: true,
      examinationTimeMin: {
        2: 20,
        3: 25
      },
      preparationTimeMin: 20,
      evaluationTimeMin: {
        2: 5,
        3: 8,
      },
    }
  },

  // telc Deutsch C1 Hochschule
  c1h : {
    name: "telc Deutsch C1 Hochschule",
    shortName: "c1h",

    written: {
      durationMin: 220,
      subtests: [
        { reading: 90 },
        { writtenBreak: 20 },
        { listening: 40 },
        { writing: 70 },
      ]
    },

    oral: {
      participantsPerSession: 2,
      plusOneForSpecial: true,
      examinationTimeMin: {
        2: 20,
        3: 25
      },
      preparationTimeMin: 20,
      evaluationTimeMin: {
        2: 5,
        3: 8
      },
    },
  },

  // telc Deutsch B1-B2 Pflege
  b12p : {
    name: "telc Deutsch B1-B2 Pflege",
    shortName: "b12p",
    written: {
      durationMin: 115, // Minutes
    },
    oral: {
      participantsPerSession: 2,
      plusOneForSpecial: false,
      examinationTimeMin: {
        1: 10,
        2: 16,
      },
      preparationTimeMin: 20,
      evaluationTimeMin: {
        1: 3,
        2: 5,
      },
    }
  },
});

export default TelcDeutschExamSettings;