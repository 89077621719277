import {Fragment} from "react";
import {printTimeHHMM} from "../../../../helpers/lib";

const OralSectionItem = props => {
  const exam = props.exam.oral;
  const examName = props.exam.type.shortName.toUpperCase();



  const oralPreparation = exam.sessions;
  const oralPreparationStart = printTimeHHMM( oralPreparation[ 0 ].start );
  const oralPreparationEnd   = printTimeHHMM( oralPreparation[ oralPreparation.length - 1 ].end );
  const preparationRoom = exam.preparationRoom;
  const supervisor = exam.supervisor;
  const prepActivityName = `${ examName } mündlich\n( Vorbereitung )`;

  const oralExamination = exam.sessionsForExaminers;

  const oralExaminationStart = printTimeHHMM( oralExamination[ 0 ].start );
  const oralExaminationEnd   = printTimeHHMM( oralExamination[ oralExamination.length - 1 ].end );
  const firstExaminer  = exam.firstExaminer;
  const secondExaminer = exam.secondExaminer;
  const examRoom = exam.examRoom;
  const examActivityName = `${ examName } mündlich ( Prüfung )`;

  return (
    <Fragment>
      <tr key={ `oral_${ props.index }_header` } >
        <td colSpan="6" > { examName } </td>
      </tr>
      <tr key={ `oral_${ props.index }_prep` } >
        <td> { oralPreparationStart } </td>
        <td> { oralPreparationEnd } </td>
        <td> { preparationRoom } </td>
        <td> { prepActivityName } </td>
        <td colSpan="2"> { supervisor } </td>
      </tr>
      <tr key={ `oral_${ props.index }_exam` } >
        <td> { oralExaminationStart } </td>
        <td> { oralExaminationEnd } </td>
        <td> { examRoom } </td>
        <td> { examActivityName } </td>
        <td> { firstExaminer } </td>
        <td> { secondExaminer } </td>
      </tr>
    </Fragment>
  );
}

export default OralSectionItem;