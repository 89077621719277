import React from 'react';
import classes from "../ExamToPrint.module.css";
import OralTimeShort from "./OralTimeShort";
import OralTimeFull from "./OralTimeFull";

const ExamCard = props => {
  return (
    <div className={ classes.examCard } onClick={ props.onClick } ref={ props.refName }>

      {/* The name of the exam*/}
      <div className={ classes.examName }>{ props.name }</div>

      <div className={ classes.timeTables }>

        {/* written exam */}
        <div className={ classes.written }>
          <div className={ classes.catName }> Schr.: </div>
          <p className={ classes.value }>
            { props.writtenStart } - { props.writtenEnd }
          </p>
        </div>

        {/* oral exam */}
        <ul className={ classes.oral }>
          { ( props.showFull )
            ? <OralTimeFull
              oralStartTime = { props.oralStartTime }
              oralEndTime   = { props.oralEndTime }
              sessions =      { props.oralSessions }
            />
            : <OralTimeShort
              oralStartTime = { props.oralStartTime }
              oralEndTime   = { props.oralEndTime }
            />
          }
        </ul>
      </div>
    </div>
  );
};

export default ExamCard;
