import React, {useContext, useEffect, useMemo, useState} from 'react';
import ExamItem from "./ExamItem";
import PageSection from "../../Layout/PageSection";
import classes from "./_ExamSetupForm.module.scss";
import AppSettings from "../../../store/appSettings";
import TelcDeutschExamSettings from "../../../store/telcDeutschExamSettings";
import TimeProfileBox from "../TimeProfile/TimeProfileBox";
import ConfirmButton from "../../UI/Controls/ConfirmButton";
import BackButton from "../../UI/Controls/BackButtion";
import ControlBoxCentered from "../../UI/Controls/ControlBox/ControlBoxCentered";

const ExamSetupForm = props => {

  // settings
  const appSettings = useContext( AppSettings );
  const telcExams   = useContext( TelcDeutschExamSettings );

  // updating the settings Profile for all calculations
  const [ appSettingsProfile, setAppSettingsProfile  ] = useState( appSettings.profiles[0] ); // Stuttgart Profile by default
  const updateSettingsProfile = props.updateSettingsProfile;
  useEffect(() => updateSettingsProfile( appSettingsProfile ), [ appSettingsProfile, updateSettingsProfile ] );

  // States: exam
  const [ firstExam, setFirstExam ] = useState("none");
  const [ secondExam, setSecondExam ] = useState("none");
  const [ thirdExam, setThirdExam ] = useState("none");
  const [ fourthExam, setFourthExam ] = useState("none");
  const [ fifthExam, setFifthExam ] = useState("none");

  // States: exam 1: written + oral
  const [ firstExamWritten, setFirstExamWritten ] = useState("0" );
  const [ firstExamOral, setFirstExamOral ] = useState("0" );

  // States: exam 2: written + oral
  const [ secondExamWritten, setSecondExamWritten ] = useState("0" );
  const [ secondExamOral, setSecondExamOral ] = useState("0" );

  const [ thirdExamWritten, setThirdExamWritten ] = useState("0" );
  // States: exam 3: written + oral
  const [ thirdExamOral, setThirdExamOral ] = useState("0" );

  // States: exam 4: written + oral
  const [ fourthExamWritten, setFourthExamWritten ] = useState("0" );
  const [ fourthExamOral, setFourthExamOral ] = useState("0" );

  // States: exam 5: written + oral
  const [ fifthExamWritten, setFifthExamWritten ] = useState("0" );
  const [ fifthExamOral, setFifthExamOral ] = useState("0" );

  // arrays of states
  const examsArray = useMemo(() => [ firstExam, secondExam, thirdExam, fourthExam, fifthExam ],
    [ firstExam, secondExam, thirdExam, fourthExam, fifthExam ]);

  const writtenExamsParticipants = [ firstExamWritten, secondExamWritten, thirdExamWritten, fourthExamWritten, fifthExamWritten ];
  const oralExamsParticipants    = [ firstExamOral, secondExamOral, thirdExamOral, fourthExamOral, fifthExamOral ];

  // arrays of stateUpdate functions
  const selectExamFunctionsArray = [ setFirstExam, setSecondExam, setThirdExam, setFourthExam, setFifthExam ];
  const writtenExamsFuncArray = [ setFirstExamWritten, setSecondExamWritten, setThirdExamWritten, setFourthExamWritten, setFifthExamWritten ];
  const oralExamsFuncArray = [ setFirstExamOral, setSecondExamOral, setThirdExamOral, setFourthExamOral, setFifthExamOral ];

  // State for "calcButton"
  const [ calcBtnEnabled, setCalcBtnEnabled ] = useState(false);

  // Building the list for exam setup
  const buildExamsList = () => {
    const onClickHandler = ( array, item, value ) => array[ parseInt( item ) ]( value );
    const array = [];
    for (let i = 0; i < appSettings.defaultNumberOfExams; i++) {
      let key = "exam_" + i;
      array.push(
        <ExamItem
          key={ key }
          id={ key }
          examNum={ i }
          onClick={ onClickHandler }

          examList={ telcExams.availableExams }
          examStatusArray={ examsArray }
          onExamSelection={ selectExamFunctionsArray }

          writtenValue=  { writtenExamsParticipants[ i ] }
          onWrittenEnter={ writtenExamsFuncArray }

          oralValue={ oralExamsParticipants[ i ] }
          onOralEnter={ oralExamsFuncArray }

          maxPartNum= { appSettingsProfile.maxNumOfParticipants }
        />
      );
    }
    return array;
  };

  // test whether we have to allow user to calculate results
  const isEnoughExamsToTest = ( exams, telc ) => {
    let counter = 0;
    const minNumOfExamsNeeded = 2;
    const emptyExam = telc.availableExams[0].type;

    for (let i = 0; i < exams.length; i++) {
      if (( exams[i] !== emptyExam ) && ( ++counter >= minNumOfExamsNeeded )) {
        return true;
      } else if ( exams[i] !== emptyExam ) counter++;
    }
    return false;
  };

  // check the status of the "calcButton"
  useEffect(() => setCalcBtnEnabled( isEnoughExamsToTest( examsArray, telcExams )),
    [ examsArray, telcExams ] );

  function formExamList( telc ) {
    const enteredExams = [];
    const noneExam = telc.availableExams[0].type;
    examsArray.forEach( ( examType, i ) => {
      if ( examType !== noneExam ) {
        enteredExams.push({
          type: telc[ examType ],
          written: { participants: writtenExamsParticipants[ i ] },
          oral:    { participants: oralExamsParticipants[ i ] },
        });
      }});
    return enteredExams;
  }

  // submit button handler function
  const submitHandler = ( event ) => {
    event.preventDefault();
    props.onClick( formExamList( telcExams ) );
  }

  // onClick Handler for "zurücksetzen" button
  const resetHandler = event => {
    event.preventDefault();
    //  resetting exams
    selectExamFunctionsArray.forEach( exam => exam("none") );

    //  resetting written
    writtenExamsFuncArray.forEach( written => written("0") );

    //  resetting oral
    oralExamsFuncArray.forEach( oral => oral("0") );

    // clear the Results field
    props.onResetClick();
  }

  // ZeitProfil form setup : if edited -> update profile
  const settingsProfileChangeHandler = profile => {
    // const settingsProfile = appSettings.profiles.find( profile => profile.id === profileID );
    // setAppSettingsProfile( settingsProfile );
    setAppSettingsProfile( profile );
  }

  const onHoursMinChangeHandler = ( value, changeHours ) => {
    if ( changeHours ) {
      const minutes = appSettingsProfile.written.defaultStartTime.getMinutes();
      appSettingsProfile.written.defaultStartTime = new Date( ...appSettings.defaultDate, value , minutes );
    } else {
      const hours = appSettingsProfile.written.defaultStartTime.getHours();
      appSettingsProfile.written.defaultStartTime = new Date( ...appSettings.defaultDate, hours , value );
    }
  }

  return (
    <PageSection>
      <form className={ classes.examSetupForm }>

        {/* setting up exams */}
        { buildExamsList() }

        {/* TimeProfile Selector */}
        <TimeProfileBox
          availableProfiles = { appSettings.availableProfiles }
          profiles = { appSettings.profiles }

          startingHour = { appSettingsProfile.written.defaultStartTime.getHours() }
          startingMinutes = { appSettingsProfile.written.defaultStartTime.getMinutes() }

          onProfileChangeHandler = { settingsProfileChangeHandler }
          onHoursMinChangeHandler = { onHoursMinChangeHandler }
        />

        {/* control panel */}
        <ControlBoxCentered>
          <ConfirmButton
            onClick = { submitHandler }
            disabled = { !calcBtnEnabled }
            text = "Berechnen"
          />
          <BackButton
            onClick = { resetHandler }
            text = "zurücksetzen"
          />

        </ControlBoxCentered>
      </form>
    </PageSection>
  );
};

export default ExamSetupForm;