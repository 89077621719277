import React from 'react';
import classes from "./PageSection.css";

const PageSection = props => {
  return (
    <section className={ classes.pageSection }>
      { props.children }
    </section>
  );
};

export default PageSection;
