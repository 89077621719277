import React from 'react';
import classes from "./_Header.module.scss";
import {useNavigate} from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <header className={ classes.header }>
        <h1 onClick = { () => navigate( "/planner" ) }>examPlanner</h1>
    </header>
  );
};

export default Header;
