import {Fragment} from "react";
import classes from "./PrintSchedule.module.scss";
import PageSection from "../../Layout/PageSection";
import ExamPlanTableHeader from "./ExamPlanTable/ExamPlanTableHeader";
import WrittenSection from "./ExamPlanTable/WrittenSection";
import OralSectionList from "./ExamPlanTable/OralSectonList";
import ExamInfoBlock from "./ExamPlanTable/ExamInfoBlock";

const PrintExamPaperSheet = props => {
  console.log( props.solution );
  const exams = props.solution.exams;

  return (
    <PageSection>

      <h1>Prüfungsplan für ........................</h1>

      <ExamInfoBlock />

      <table className={ classes.layout }>
        <ExamPlanTableHeader/>
        <tbody>

          {/* Schriftlich */}
          <WrittenSection exams = { exams } />

          {/* Mündlich */}
          <OralSectionList exams = { exams } />

        </tbody>
      </table>
    </PageSection>
  );
}

export default PrintExamPaperSheet;