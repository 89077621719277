import React, {Fragment} from 'react';
import classes from "../ExamToPrint.module.css";
import OralHeader from "./Atoms/OralHeader";
import {printTimeHHMM} from "../../../../../helpers/lib";

const OralTimeFull = props => {
  function printSessionTime( sessions ) {
    const sessionList = [];
    sessions.forEach( ( session, item ) => {
      sessionList.push(
        <li key={ "item_" + item }>
          <div className={ classes.catName }>Runde { item + 1 }: </div>
          <p className={ classes.value }>
            { printTimeHHMM( session.start ) } - { printTimeHHMM( session.end ) }
          </p>
        </li>
      );
    });
    return sessionList;
  }

  return (
    <Fragment>
        {/* Overall duration */}
        <li key={ "overallDuration" } ref={ props.refName }>
          <OralHeader/>
          <p className={ classes.value }>
            { props.oralStartTime } - { props.oralEndTime }
          </p>
        </li>

        {/* Each session duration */}
        { printSessionTime( props.sessions ) }
    </Fragment>
  );
};

export default OralTimeFull;
