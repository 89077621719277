import classes from "./_GetRoomsAndPeople.module.scss";
import GetExamInfoItem from "./GetExamInfoItem";

const SetRoomsAndPeople = props => {

  const getExamInfoList = props.exams.map( ( exam, index ) =>
    <GetExamInfoItem
      exam = { exam }
      number = { index + 1 }
      timeProfile = { props.timeProfile }
      key = { exam.type.name + "_" + index }
    /> );

  return (
    <form className={ classes.setRoomsAndPeople }>

      {/* print exams to set Info for */}
      { getExamInfoList.map( item => item ) }

    </form>
  );
}

export default SetRoomsAndPeople;