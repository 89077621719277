import React, {useState} from 'react';
import {printTimeHHMM, scrollTo} from "../../../../helpers/lib";
import ExamCard from "./ExamCard/ExamCard";

const ExamToPrint = props => {
  const [ showFull, setShowFull ] = useState( false );

  const oralStartTime = printTimeHHMM( props.oral[ 0 ].start );
  const oralEndTime =   printTimeHHMM( props.oral[ props.oral.length - 1 ].end) ;

  const examOralSectionRef = React.useRef(null);

  const shortOnClickHandler = event => {
    setShowFull( true );
    setTimeout(() => scrollTo( examOralSectionRef ), 50);
  };
  const fullOnClickHandler  = () => setShowFull( false );

  return (
    <ExamCard
      name =          { props.name }
      writtenStart =  { props.writtenStart }
      writtenEnd =    { props.writtenEnd }
      oralStartTime = { oralStartTime }
      oralEndTime =   { oralEndTime }
      oralSessions =  { props.oral }
      showFull =      { showFull }
      refName =       { examOralSectionRef }

      onClick = { ( showFull ) ? fullOnClickHandler : shortOnClickHandler }
    />
  );
};

export default ExamToPrint;