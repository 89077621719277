import WelcomeMessage from "../components/Tafel/WelcomeMessage";
import TelcExamTimetable from "../components/Tafel/components/ExamInfo/TelcExamTimetable";
import ControlBox from "../components/Tafel/ControlBox";
import NormalPage from "../components/Tafel/components/Layout/NormalPage";
import {FullPage, Slide} from "react-full-page";
import ExamInfo from "../components/Tafel/pages/ExamInfo";
import {useContext} from "react";
import TelcDeutschExamSettings from "../store/telcDeutschExamSettings";

const Tafel = () => {
  const telcExams = useContext( TelcDeutschExamSettings );
  // console.log( telcExams.c1h );
  // console.log( telcExams["c1h"] );

  const exam = telcExams["c1h"];

  return (
    <FullPage>
      <Slide>
        <ExamInfo />
      </Slide>
      <Slide>
        <TelcExamTimetable exam = { exam }/>
      </Slide>
      <Slide>
        <NormalPage
          headerText="Page 3"
        />
      </Slide>
    </FullPage>
  );
}

export default Tafel;
