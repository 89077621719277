import React from 'react';
import Card from "../../UI/Card/Card";
import classes from "./Option.module.scss";
import {minToTime, printTimeHHMM} from "../../../helpers/lib";
import ExamList from "./ExamListShort/ExamListShort";
import ConfirmButton from "../../UI/Controls/ConfirmButton";
import { useNavigate } from "react-router-dom";

const Option = props => {
  const examOrderWithArrows = props.examOrder.replaceAll( " ", " > " );
  const navigate = useNavigate();

  // printButton Handler
  const nextButtonHandler = ( event ) => {
    event.preventDefault();
    props.setExamsToPrint( props.solution );
    navigate("/planner/print");
  }

  return (
    <Card>
      <div className={ classes.optionNumber } >Option { props.optionNumber }</div>
      <div className={ classes.examOrder }>
        <p> { examOrderWithArrows } </p>
      </div>

      {/* Solution Time Info */}

      <div className={ classes.timeBlock } >
        <div className={ classes.overallDurationMin } >
          <p className={ classes.catName }>Dauer: </p>
          <p>
            { minToTime( props.overallDuration ) }
          </p>
        </div>
      </div>

      {/* Start & End Time of the solution */}

      <div className={ classes.optionStartEndTime } >
        <div>
          <p className={ classes.catName }>Start: </p>
          <p>{ printTimeHHMM( props.start ) }</p>
        </div>
        <div>
          <p className={ classes.catName }>Ende: </p>
          <p>{ printTimeHHMM( props.end ) }</p>
        </div>
      </div>

      {/* List all exams of the solution */}
      <ExamList examsToList={ props.examList } />

      {/* NextButton to print the solution */}
      <div className={ classes.nextButtonBlock }>
          <ConfirmButton
            onClick = { nextButtonHandler }
            text = "Ausdrucken..."
          />
      </div>
    </Card>
  );
};

export default Option;