import React from "react";

const AppSettings = React.createContext({
  defaultDate: [2021, 6, 15],
  defaultNumberOfExams: 5,
  availableProfiles: [
    { type: "stu", name: "Fokus Stuttgart" },
    { type: "mun", name: "Fokus München" },
  ],
  profiles: [
    // Stuttgart
    {
      id: "stu",
      maxNumOfParticipants: 13,
      minBreakBetweenWrittenAndOralMin: 20, // Minutes
      minBreakBetweenExamsMin: 30, // Minutes

      written: {
        defaultStartTime: new Date(2021, 6, 15, 9, 0), // 9:00
        introDurationMin: 30, // Minutes
        maxAmountOfParticipantsPerGroup: 8,
        possibleParticipantNumber: [
          { text: "1 oder mehr" },
          { text: "keine" },
        ],
      },

      oral: {
        defaultStartTime: new Date(2021, 6, 15, 12, 0), // 12:00
        introDurationMin: 3,
        cleaningBreakMin: 3,
      },
    },
    // München
    {
      id: "mun",
      oralMaxNumOfParticipants: 20,
      minBreakBetweenWrittenAndOralMin: 20, // Minutes
      minBreakBetweenExamsMin: 10, // Minutes

      written: {
        defaultStartTime: new Date(2021, 6, 15, 14, 0), // 9:00
        introDurationMin: 20, // Minutes
        maxAmountOfParticipantsPerGroup: 8,
        possibleParticipantNumber: [
          { text: "1 oder mehr" },
          { text: "keine" },
        ],
      },

      oral: {
        defaultStartTime: new Date(2021, 6, 15, 16, 0), // 12:00
        introDurationMin: 10,
        cleaningBreakMin: 5,
      },
    },
  ],
});

export default AppSettings;