import React, {Fragment, useEffect, useState} from "react";
import ExamSetupForm from "../Exams/ExamSetupForm";
import ResultPresentation from "../Results/ResultPresentation";
import {
  adjustTimingForEachTimeTable,
  calcParametersForTimeTables,
  generateProposals,
  generateTimeTables, printSummary, scrollTo, sortTimeTables
} from "../../../helpers/lib";

const SetExamData = props => {
  const [ settingsProfile, setSettingsProfile ] = useState("bad_Profile");
  const [ examsToTest, setExamsToTest ] = useState([] );
  const [ fastestOptions, setFastestOptions ] = useState([] );
  const resultsRef = React.useRef(null);

  useEffect(() => {}, [ fastestOptions ] );

  const updateSettings = profile => setSettingsProfile( profile );

  const calcResults = examList => {
    // clear the previous Results
    setExamsToTest( [] );

    // print new Results
    setExamsToTest( examList );

    // generate all possible combination of exam position
    const layoutProposals = generateProposals( examList );

    // create timeTables for all layout proposals
    const timeTables = generateTimeTables( layoutProposals );

    // calculate values for timetable items
    calcParametersForTimeTables( timeTables, settingsProfile );

    // adjust timetable in accordance to the earliest exam
    adjustTimingForEachTimeTable( timeTables, settingsProfile );

    // sort timeTables
    sortTimeTables( timeTables );

    // find 3 best solutions
    const fastestSolutions = timeTables.slice(0, 3);
    setFastestOptions( fastestSolutions );
    // console.log( fastestSolutions );

    const longestSolution = timeTables[ timeTables.length - 1 ];
    // console.log( longestSolution );

    // present the best solution
    printSummary( examList, fastestSolutions, longestSolution );
    // console.log( "examList:" );
    // console.log( examList );

    // console.log( "fastestSolutions:" );
    // console.log( fastestSolutions );
    // console.log( longestSolution );

    // scroll to Results-Section
    setTimeout(() => scrollTo( resultsRef ), 50);
  }

  // what to do, when the user press on "zurücksetzen"
  const resetHandler = () => setFastestOptions([]);

  return(
    <Fragment>

      <ExamSetupForm
        onClick={ calcResults }
        onResetClick={ resetHandler }
        updateSettingsProfile={ updateSettings }
      />

      <ResultPresentation
        testedExams={ examsToTest }
        fastestSolutions={ fastestOptions }
        refName={ resultsRef }
        setExamsToPrint = { props.setExamsToPrint }
      />
    </Fragment>
  );
}

export default SetExamData;