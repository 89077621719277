import React from 'react';
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={ classes.footer }>
      <p>&copy; 2021 <a href="https://schneideralex.de">Alex Schneider</a></p>
    </footer>
  );
};

export default Footer;
