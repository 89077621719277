import React, {Fragment} from 'react';
import classes from "../ExamToPrint.module.css";
import OralHeader from "./Atoms/OralHeader";

const OralTimeShort = props => {
  return (
    <Fragment>
        {/* Overall duration */}
        <li key={ "overallDuration" }>
          <OralHeader/>
          <p className={ classes.value }>
            { props.oralStartTime } - { props.oralEndTime }
          </p>
        </li>
    </Fragment>
  );
};

export default OralTimeShort;