import classes from "./NormalPage.module.scss";
import TafelHeader from "./TafelHeader";

const NormalPage = props => {
  return (
      <div className={ classes.normalPage }>
        <TafelHeader headerText={ props.headerText } />
        <div className="normalPage--content">
          { props.children }
        </div>
      </div>
  );
}

export default NormalPage;