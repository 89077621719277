import React from 'react';
import Option from "./Option";
import classes from "./ResultPresentation.module.css";

const ResultPresentation = props => {
  const output = [];
  props.fastestSolutions.forEach( ( solution, item ) => output.push(
    <Option
      key={ solution.overallDurationMin * item }
      optionNumber={ item + 1 }
      start={ solution.start }
      end={ solution.end }
      examOrder={ solution.shortName }
      oralDuration={ solution.oralDurationMin }
      overallDuration={ solution.overallDurationMin }
      examList={ solution.exams }
      setExamsToPrint = { props.setExamsToPrint }
      solution = { solution }
  /> ) );
  return (
  <section className={ classes.resultSection } ref={ props.refName }>
    { output }
  </section>
  );
};

export default ResultPresentation;
