import {Fragment, useState} from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import {Route, Routes} from "react-router-dom";
import SetExamData from "../components/Planner/Print/SetExamData";
import PrintSetup from "../components/Planner/Print/PrintSetup";
import PrintExamPaperSheet from "../components/Planner/Print/PrintExamPaperSheet";

const Planner = () => {
  const [ examsToPrint, setExamsToPrint ] = useState([] );

  return (
    <Fragment>
      <Header />
      <main>
        <Routes>
          <Route path = "/" element = { <SetExamData setExamsToPrint = { setExamsToPrint } /> }/>
          <Route path = "print/plan" element = { <PrintExamPaperSheet solution = { examsToPrint } /> } />
          <Route path = "print/*" element = { <PrintSetup solution = { examsToPrint } /> } />
        </Routes>
      </main>
      <Footer/>
    </Fragment>
  );
}

export default Planner;