import {Fragment, useContext} from "react";
import AppSettings from "../../../store/appSettings";
import TelcDeutschExamSettings from "../../../store/telcDeutschExamSettings";
import PageSection from "../../Layout/PageSection";
import PrintControlBox from "./PrintControlBox";
import SetRoomsAndPeople from "./SetRoomsAndPeople";

const PrintSetup = props => {
  // settings
  const appSettings = useContext( AppSettings );
  const telcExams   = useContext( TelcDeutschExamSettings );
  // console.log( "solution:" )
  // console.log( props.solution )
  // console.log( props.solution.timeProfile )
  return (
      <PageSection>
        {/* Wir brauchen Info */}
        <SetRoomsAndPeople
          exams = { props.solution.exams }
          timeProfile = { props.solution.timeProfile }
        />

        {/* Control Panel */}
        <PrintControlBox solution = { props.solution } />
      </PageSection>
  );
}

export default PrintSetup;