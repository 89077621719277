import {useState} from "react";
import classes from "./_WrittenOralnput.module.scss";

const WrittenInput = props => {
  const [aufsicht, setAufsicht] = useState("Max Mustermann");
  const [raum, setRaum] = useState("0");

  const exam = props.exam.written;
  const groupNumber = props.groupNumber;

  exam[ "numOfGroups" ] = groupNumber;

  const supervisorKey = `supervisor_${ groupNumber }`;
  const roomKey       = `room_${ groupNumber }`;

  function onChangeRoomHandler( event ) {
    setRaum( event.target.value );
    exam[ roomKey ] = raum;
  }

  function onChangeSupervisorHandler( event ) {
    setAufsicht( event.target.value );
    exam[ supervisorKey ] = aufsicht;
  }

  return (
    <fieldset className={ classes.writtenFieldset } key={ `exam_${ groupNumber }` }>
      <legend>Schriftlich ( Gruppe { groupNumber } ) </legend>

      {/* Aufsicht */}
      <label htmlFor={ supervisorKey }>Aufsicht : </label>
      <input type="text" value={ aufsicht } name={ supervisorKey } size="30" onChange={ onChangeSupervisorHandler }/>

      {/* Raum */}
      <label htmlFor={ roomKey }>Raum : </label>
      <input type="number" value={ raum } name={ roomKey } size="3" min="1" onChange={ onChangeRoomHandler } />
    </fieldset>
  );
}

export default WrittenInput;