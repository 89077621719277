import React from 'react';
import classes from "./_ExamItem.module.scss";

const ExamItem = props => {

  // Selector lists
  const examList =
    props.examList.map( ( exam, i ) =>
      <option key={ "exam_" + i } value={ exam.type } >{ exam.name }</option> );

  // Handlers
  const examChangeHandler = event =>
    props.onClick( props.onExamSelection, props.examNum, event.target.value );

  const writtenOralChangeHandler = ( event, isWritten ) => {
    const whatToUpdate = ( isWritten ) ? props.onWrittenEnter : props.onOralEnter;
    let value = event.target.value;

    // remove zero
    if (( value.length > 1 ) && ( value.charAt(0) === "0" )) {
      value = value.substring( 2, value.length - 1);
    }

    value = ( +value > props.maxPartNum ) ? props.maxPartNum : value;
    props.onClick( whatToUpdate, props.examNum, value );
  }

  const writtenChangeHandler = event => writtenOralChangeHandler( event, true );
  const oralChangeHandler    = event => writtenOralChangeHandler( event, false );

  return (
    <div className={ classes.examItem }>

      {/* Number of the exam */}
      { parseInt( props.examNum ) + 1 + ". " }

      {/* which exam? */}
      <label htmlFor={ "e_" + props.id }>Prüfung: </label>
      <select
        name="exams"
        id={ "e_" + props.id }
        onChange={ examChangeHandler }
        value={ props.examStatusArray[ props.examNum ] }
      >{ examList }</select>

      {/* More than 0 written participants? */}
      <label htmlFor={ "w_" + props.id }>Schriftlich: </label>
      <input
          id={ "w_" + props.id }
        name="participantsWritten"
        type="number"
        size="1"
        min="0"
        max={ props.maxPartNum }
        step="1"
        value={ props.writtenValue }
        onFocus={ event => event.target.select() }
        onChange={ writtenChangeHandler }
      />

      {/* How many oral participants? */}
      <label htmlFor={ "o_" + props.id }>Mündlich: </label>
      <input
        id={ "o_" + props.id }
        name="participantsOral"
        type="number"
        size="1"
        min="0"
        max={ props.maxPartNum }
        step="1"
        value={ props.oralValue }
        onFocus={ event => event.target.select() }
        onChange={ oralChangeHandler }
        />
    </div>
  );
};

export default ExamItem;