import NormalPage from "../components/Layout/NormalPage";

const ExamInfo = props => {
  return (
    <NormalPage
      headerText="Cool exam Info page"
    >
      <p>Page Content</p>
    </NormalPage>
  );
}

export default ExamInfo;