import TelcDeutschExamSettings from "../../../../store/telcDeutschExamSettings";
import classes from "./TelcExamTimetable.module.scss";
import {useContext} from "react";
import NormalPage from "../Layout/NormalPage";

const ExamSubpartHeader = props => {
  return (
    <tr className={ classes.examPartHeader }>
      <td>{ props.name }</td>
      <td>Min.</td>
    </tr>
  );
}

const ExamSubpartItem = props => {
  return (
    <tr>
      <td>{ props.name }</td>
      <td>{ props.duration }</td>
    </tr>
  );
}

const TelcExamTimetable = props => {
  const telcExams = useContext( TelcDeutschExamSettings );
  const exam = props.exam;
  console.log( exam );

  const writtenTimetable = exam => {
    return (
      <ul>
        {/*<li> Hello </li>*/}
        { exam.written.subtests.map( el => <li> { Object.entries(el).flat() } </li> ) }

      </ul>
    );
    //)
  }

  const muendlichTimetable = (
    <ExamSubpartItem name="oral 1" duration="50" />
  );



  return (
    <NormalPage
      headerText={ exam.name }
    >
      <div className="written">
        { writtenTimetable( exam ) }
      </div>
      <div className="oral">

      </div>

      <table>
        <tbody>
        <ExamSubpartHeader name="Schriftliche Prüfung" />
        {/*{ writtenTimetable }*/}

        <ExamSubpartHeader name="Mündliche Prüfung" />
        {/*{ muendlichTimetable }*/}
        </tbody>
      </table>
    </NormalPage>
  );
}

export default TelcExamTimetable;