import './App.css';
import {Link, Outlet, Route, Routes} from "react-router-dom";
import Tafel from "./pages/Tafel";
import Welcome from "./components/Tafel/Welcome";
import Planner from "./pages/Planner";
import PrintExamPaperSheet from "./components/Planner/Print/PrintExamPaperSheet";
import Timer from "./pages/Timer";

function App() {
  return (
    <div>
      <h1>APP Page</h1>
      <nav>
        <Link to="/planner">Planner</Link> | { " " }
        <Link to="/tafel">Tafel</Link> | { " " }
        <Link to="/print">Print</Link> | { " " }
        <Link to="/timer">Timer</Link>
      </nav>
      <Outlet/>
    </div>
  )
}

export default App;