const minToMilliSec = ( time, toMilliSec ) => {
  toMilliSec = (typeof toMilliSec !== 'undefined') ? toMilliSec : true
  return ( toMilliSec )
    ? time * 60 * 1000
    : time / ( 60 * 1000 ) ;
}

const findMinMax = ( array, useDate, getMinValue ) => {
  useDate = ( useDate === undefined ) ? false : useDate;
  getMinValue = ( getMinValue === undefined ) ? true : getMinValue;

  let minMaxValue = array[0];
  array.forEach( item => {
    if ( useDate ) {
      if (( item.getTime() < minMaxValue.getTime() && getMinValue )
        || ( item.getTime() > minMaxValue.getTime() && !getMinValue )) minMaxValue = item
    } else {
      if (( item < minMaxValue && getMinValue ) || ( item > minMaxValue && !getMinValue )) minMaxValue = item
    }
  });
  return minMaxValue;
}

const getNumberOfSessions = ( participants, participantsPerSession, plusOneParticipantInFinalSessionAllowed ) => {
  let numOfSessions = ( participants > participantsPerSession ) ? participants / participantsPerSession >> 0 : 1;
  let hasNonStdSession = ( participants - numOfSessions * participantsPerSession ) > 0
  return ( hasNonStdSession && !plusOneParticipantInFinalSessionAllowed )
    ? [ numOfSessions + 1, hasNonStdSession ]
    : [ numOfSessions, hasNonStdSession ];
}

// scrolling to the part of the page identified with "ref"
export const scrollTo = ref => {
  if (!ref.current ) return;
  ref.current.scrollIntoView( { behavior: "smooth" } );
}

export const calcOralExamDurationMin = exam => {
  const firstSessionStart = exam.oral.sessions[0].start.getTime();
  const lastSessionEnd    = exam.oral.sessions[exam.oral.sessions.length - 1].end.getTime();
  return minToMilliSec( lastSessionEnd - firstSessionStart, false );
}

export const generateProposals = listOfExams => {
  function generatePossibilities( dataList, result, prefix ) {
    if ( dataList.length === 1 ) {
      result.push( [ ...prefix, dataList[0] ] );
    } else {
      for ( let i = 0; i < dataList.length; i++ ) {
        const newResult = [];
        const newPrefix = [ ...prefix, dataList[i] ];
        const newList = copyArrayWithoutCurrentValue( dataList, i );
        generatePossibilities( newList, newResult, newPrefix );
        result.push( ...newResult );
      }
    }
  }

  function copyArrayWithoutCurrentValue( oldArray, item ) {
    const newArray = Array.from( oldArray );
    newArray.splice( item, 1 );
    return newArray;
  }

  const layoutProposals = [];
  for (let i = 0; i < listOfExams.length; i++) {
    const newList = copyArrayWithoutCurrentValue( listOfExams, i );
    generatePossibilities( newList, layoutProposals, [ listOfExams[i] ] );
  }
  return layoutProposals;
}

//
export const generateTimeTables = layouts => {
  const timeTables = [];
  const timeTableProto = {
    firstExamStart: new Date(),
    lastExamEnd: new Date(),
    duration: undefined,
    exams: [],
  }

  layouts.forEach( layout => {
    // copy exam to the list of exams in this layout
    const newTimeTable = Object.create( timeTableProto );
    newTimeTable.exams = [];

    layout.forEach( exam => {
      newTimeTable.exams.push(
        JSON.parse(JSON.stringify( exam )));
    });
    timeTables.push( newTimeTable );
  });

  return timeTables;
}

// uses:
// calc: start / end for oral sessions, oralExam duration, ???
// output: Date() when the last oral session ends
export const calcParametersForTimeTables = ( tables, settings ) => {

  // oral exams: how many sessions and start / end for each session
  function calcOralSessionsAndSessionEnd( exam, startingTime, settings ) {
    let sessions, oralStartingTime, oralEndingTime, sessionDurationMin, activeExamDurationMin, preparationTimeAndCleaningMin,
      sessionsForExaminers, numberOfSessions, hasNonStdSession, participantsPerSpecialSession, timeDeltaForExaminerSessions,
      oralStartingTimeForExaminers, oralEndingTimeForExaminers;

    // calc amount of oral sessions
    [numberOfSessions, hasNonStdSession] = getNumberOfSessions(
      exam.oral.participants,
      exam.type.oral.participantsPerSession,
      exam.type.oral.plusOneForSpecial);

    participantsPerSpecialSession = ( exam.oral.participants === 1 )
      ? 1 // if only 1 participant -> then only 1
      : exam.type.oral.participantsPerSession;

    // generate session start / end times
    sessions = [];
    sessionsForExaminers = [];
    oralStartingTime = new Date ( startingTime.getTime() );

    // loop through sessions
    for ( let i = 1; i <= numberOfSessions; i++ ) {

      // last special session
      if ( hasNonStdSession && i === numberOfSessions ) {

        participantsPerSpecialSession = ( i > 1 )
          ? exam.oral.participants - ( numberOfSessions - 1 ) * exam.type.oral.participantsPerSession
          : exam.oral.participants;

        activeExamDurationMin =
          exam.type.oral.examinationTimeMin[participantsPerSpecialSession] +
          exam.type.oral.evaluationTimeMin[participantsPerSpecialSession]

      } else {
        activeExamDurationMin =
          exam.type.oral.examinationTimeMin[exam.type.oral.participantsPerSession] +
          exam.type.oral.evaluationTimeMin[exam.type.oral.participantsPerSession]
      }

      sessionDurationMin =
        exam.type.oral.preparationTimeMin +
        activeExamDurationMin +
        settings.oral.introDurationMin;

      oralEndingTime = new Date ( oralStartingTime.getTime() + minToMilliSec( sessionDurationMin ) );

      // duration of the preparation before oral part
      // a1: 0 -> duration of the first session
      // b1 / b2 / c1 : 20 Min + 5 for cleaning
      sessions.push( {
        start: new Date( oralStartingTime.getTime() ),
        end:   new Date( oralEndingTime.getTime() ),
        participants: ( hasNonStdSession ) ? participantsPerSpecialSession : exam.type.oral.participantsPerSession,
        duration: sessionDurationMin,
      });

      // calculate time for preparation before testing ( + cleaning the room )
      if ( exam.type.oral.preparationTimeMin === 0 ) {
        // testing duration + evaluation time
        preparationTimeAndCleaningMin =
          exam.type.oral.examinationTimeMin[exam.type.oral.participantsPerSession] +
          exam.type.oral.evaluationTimeMin[exam.type.oral.participantsPerSession]

        // of no preparation time => no delta in participants & examiners time
        timeDeltaForExaminerSessions = 0;

      } else {
        // for all not-first-sessions = + cleaningTime
        preparationTimeAndCleaningMin = exam.type.oral.preparationTimeMin + settings.oral.cleaningBreakMin;

        // calculating delta to set time for examiners
        timeDeltaForExaminerSessions = exam.type.oral.preparationTimeMin;
      }

      // adding introduction time
      preparationTimeAndCleaningMin += settings.oral.introDurationMin;
      timeDeltaForExaminerSessions += settings.oral.introDurationMin;

      // The same but for examiners
      oralStartingTimeForExaminers = oralStartingTime.getTime() + minToMilliSec( timeDeltaForExaminerSessions );
      oralEndingTimeForExaminers = oralEndingTime.getTime() + minToMilliSec( timeDeltaForExaminerSessions );

      sessionsForExaminers.push( {
        start: new Date( oralStartingTimeForExaminers ),
        end:   new Date( oralEndingTimeForExaminers ),
        participants: ( hasNonStdSession ) ? participantsPerSpecialSession : exam.type.oral.participantsPerSession,
      });

      // set starting time for the new session
      oralStartingTime.setTime( oralStartingTime.getTime() + minToMilliSec( preparationTimeAndCleaningMin ));
    }

    // add info to the exam object
    exam.oral.sessions = sessions;
    exam.oral.sessionsForExaminers = sessionsForExaminers;
    return oralEndingTime;
  }

  function calcWrittenSession(exam, oralSessionStart, settings) {
    if ( exam.written.participants > 0 ) {
      const breakBetweenExamParts = minToMilliSec( settings.minBreakBetweenWrittenAndOralMin );
      const introDuration = minToMilliSec( settings.written.introDurationMin );
      const writtenPartDuration = minToMilliSec( exam.type.written.durationMin );
      const writtenSessionDurationWithBreak = introDuration + writtenPartDuration + breakBetweenExamParts;

      exam.written.start = new Date( oralSessionStart.getTime() - writtenSessionDurationWithBreak );
      exam.written.end =   new Date( oralSessionStart.getTime() - breakBetweenExamParts );
    } else {
      exam.written.start = new Date( oralSessionStart.getTime() );
      exam.written.end =   new Date( oralSessionStart.getTime() );
    }
  }

  // go through all generated timetables
  tables.forEach( table => {

    // set Time Profile
    table.timeProfile = settings.id;

    // set the starting time for oral exam for the layout;
    const oralSessionStartingTime = new Date( settings.oral.defaultStartTime.getTime() );

    for (let i = 0; i < table.exams.length; i++) {

      // select current exam
      const exam = table.exams[i];

      // for second exam and later
      // if the exam has a preparation time -> start the oral session earlier
      if ( i > 0 ) {
        let shiftMin = ( exam.type.oral.preparationTimeMin !== 0 ) ? exam.type.oral.preparationTimeMin : 0;
        let newStartingTime = oralSessionStartingTime.getTime() - minToMilliSec( shiftMin );
        oralSessionStartingTime.setTime( newStartingTime );
      }

      // calculate for each exam:
      // * timing ( start + end ) for oral sessions
      // * oral ending time
      const lastSessionEndsAtMin = calcOralSessionsAndSessionEnd( exam, oralSessionStartingTime, settings );

      // * written starting time
      // * written ending time
      calcWrittenSession( exam, exam.oral.sessions[0].start, settings );

      // calc oral exam duration
      exam.oral.examDurationMin = calcOralExamDurationMin( exam );

      // update the starting time for the next oral exam for the layout;
      oralSessionStartingTime.setTime( lastSessionEndsAtMin.getTime() + minToMilliSec( settings.minBreakBetweenExamsMin ));
    }
  });
}

export const adjustTimingForEachTimeTable = ( tableList, settings ) => {
  const findEarliestExamInTimetable = table => {
    let earliestTimeInTable = table.exams[0].written.start;

    table.exams.forEach( exam => {
      const earliestTime = earliestTimeInTable.getTime();
      const currentTime = exam.written.start.getTime();
      if ( currentTime < earliestTime ) {
        earliestTimeInTable = exam.written.start;
      }
    });
    return earliestTimeInTable;
  }

  function getTimeDifference( earliest , settings) {
    const defaultTime = settings.written.defaultStartTime.getTime();
    const earliestStartingTime = earliest.getTime();
    return earliestStartingTime - defaultTime;
  }

  function adjustTiming( table, delta ) {
    const newTime = time => {
      return ( delta < 0 )
        ? time.getTime() - delta  // adding time ( minus { -Time }) if the earliest exam is too early
        : time.getTime() - delta; // subtract time if too late
    }

    if ( delta !== 0 ) {
      table.exams.forEach( exam => {

        // written
        exam.written.start.setTime( newTime( exam.written.start ));
        exam.written.end.setTime( newTime( exam.written.end ));

        // oral : for participants
        exam.oral.sessions.forEach( session => {
          session.start.setTime( newTime( session.start ));
          session.end.setTime( newTime( session.end ));
        });

        // oral : for examiners
        exam.oral.sessionsForExaminers.forEach( sessionForExaminer => {
          sessionForExaminer.start.setTime( newTime( sessionForExaminer.start ));
          sessionForExaminer.end.setTime( newTime( sessionForExaminer.end ));
        });
      });
    }
  }

  function sortExamsInTable( table ) {
    function writtenStart( examA, examB ) {
      if ( examA.written.start.getTime() < examB.written.start.getTime() ) return -1;
      if ( examA.written.start.getTime() > examB.written.start.getTime() ) return 1;
      return 0;
    }
    table.sort( writtenStart );
  }

  function calcTableProperties( table ) {
    function getLastSessionTime( t ) {
      const list = [];
      t.exams.forEach( exam => {
        const lastSession = exam.oral.sessions[ exam.oral.sessions.length - 1 ];
        list.push( lastSession.end );
      });
      return list;
    }

    function findTheLatestExamFinish(list) {
      let theLatest = list[0];
      list.forEach( item => {
        if ( theLatest.getTime() < item.getTime() ) theLatest = item;
      });
      return theLatest;
    }

    // calculation for table
    const firstExam = table.exams[0];
    const listOfExamLastSessionTime = getLastSessionTime( table );
    const lastExamLastOralSession = findTheLatestExamFinish( listOfExamLastSessionTime );
    table.start = firstExam.written.start;
    table.end = lastExamLastOralSession;
    table.overallDurationMin = minToMilliSec( table.end - table.start, false);

    function calcOralDuration( table ) {

      function getOralStartsEnds(arrayOfExams, getStarts) {
        getStarts = ( getStarts === undefined ) ? true : getStarts;

        const resultArray = [];
        arrayOfExams.forEach( exam => {
          const sessions = exam.oral.sessions;
          resultArray.push(( getStarts ) ? sessions[0].start : sessions[ sessions.length - 1 ].end)
        });
        return resultArray;
      }
      const allOralExamStarts = getOralStartsEnds( table.exams );
      const allOralExamEnds =   getOralStartsEnds( table.exams, false );
      const earliestStart = findMinMax( allOralExamStarts );
      const latestEnd = findMinMax( allOralExamEnds, false, false );
      const oralTeamWorkingTime = latestEnd.getTime() - earliestStart.getTime();
      return minToMilliSec( oralTeamWorkingTime, false );
    }

    table.oralDurationMin = calcOralDuration( table );

    function formName(table) {
      let name = "";
      table.exams.forEach( exam => {
        name+= `${ exam.type.shortName } `.toUpperCase();
      });
      return name.slice(0, -1);
    }
    table.shortName = formName( table );
  }

  tableList.forEach( table => {

    // check whether the written time is the earliest
    const earliestWrittenStartTimeInTimeTable = findEarliestExamInTimetable( table );

    // calculate the difference between the earliest and default starting time
    const delta = getTimeDifference( earliestWrittenStartTimeInTimeTable, settings );

    // adjust all starting / ending time for all exams
    adjustTiming( table, delta );

    // sort exams by writing exam start: 0 -> the earliest
    //   console.log( typeof table );
    //   console.log( table );
    sortExamsInTable( table.exams );

    // calculate the end of the timetable
    //  * set timeTable start
    //  * set timeTable end
    //  * calc timetable duration
    calcTableProperties( table );
  });
}

// sorting inside the array
export const sortTimeTables = timeTables => {
  function arrayCompareObjects( a, b ) {
    if ( a.overallDurationMin < b.overallDurationMin ) return -1;
    if ( a.overallDurationMin > b.overallDurationMin ) return 1;
    return 0;
  }
  timeTables.sort( arrayCompareObjects );
}

// returns duration ( in minutes ) in text - Hours and Minutes format
export const minToTime = timeInMin => {
  const minutes = timeInMin % 60;
  const hours = (timeInMin - minutes) / 60;
  return `${ hours } Std. ${ minutes } Min.`;
}

export const printTimeHHMM =  date => {
  const toDouble = x => ( x < 10 ) ? "0" + x : x;
  return `${ toDouble( date.getHours().toLocaleString() ) }:${ toDouble( date.getMinutes())}`;
}

// final print to console of the summary for presentation
export const printSummary = ( examsToTest, fastestSolutions, longestSolution ) => {
  const line = "=".repeat(50);
  console.log(`
${ line }
${" ".repeat(19)} F A Z I T
${ line }

Prüfungen: ${ examsToTest.length }

`);

  examsToTest.forEach(( exam, i ) => {
    console.log(
      `${ i + 1 }: ${ exam.type.name } - Schr.: ${ exam.written.participants } | Münd.: ${ exam.oral.participants }`
    )
  });

  console.log(`
Schnellste Option: ${ minToTime( fastestSolutions[0].overallDurationMin ) }  |  ${ fastestSolutions[0].shortName } 
Längste Option:    ${ minToTime( longestSolution.overallDurationMin ) }  |  ${ longestSolution.shortName }
`);

  fastestSolutions.forEach(( solution, item ) => {
    let timingList = "";
    solution.exams.forEach( exam => {
      timingList += `${" ".repeat(14)} [${ exam.type.shortName.toUpperCase() }] Schr.: ${ printTimeHHMM( exam.written.start ) } - ${ printTimeHHMM( exam.written.end ) } | Münd.: ${ printTimeHHMM( exam.oral.sessions[0].start ) } - ${ printTimeHHMM( exam.oral.sessions[ exam.oral.sessions.length - 1 ].end ) } \n`
    });

    console.log(`
Option ${ item + 1 }
  
  Reihenfolge: ${ solution.shortName }
 
       Beginn: ${ printTimeHHMM( solution.start ) } 
         Ende: ${ printTimeHHMM( solution.end ) }

        Dauer: ${ minToTime( solution.overallDurationMin ) }
MündlichDauer: ${ minToTime( solution.oralDurationMin) }  
       
       Timing: 
${ timingList }
         
`);
  });
}