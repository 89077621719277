import React from 'react';

import classes from "./ExamListShort.module.css";
import {printTimeHHMM} from "../../../../helpers/lib";
import ExamToPrint from "./ExamToPrint";

const ExamListShort = props => {

  function printListOfExams(list) {
    const array = [];
    list.forEach( exam => {
      array.push(

        <ExamToPrint
          key = { Math.random() }
          name = { exam.type.shortName.toUpperCase() }
          writtenStart = { printTimeHHMM( exam.written.start ) }
          writtenEnd = { printTimeHHMM( exam.written.end ) }
          oral = { exam.oral.sessions }
        /> );

    } );
    return array;
  }
  return (
    <div className = { classes.examList }>
      { printListOfExams( props.examsToList ) }
    </div>
  );
};

export default ExamListShort;
