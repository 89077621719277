import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Tafel from "./pages/Tafel";
import Planner from "./pages/Planner";
import Timer from "./pages/Timer";
import Welcome from "./components/Tafel/Welcome";
import PrintExamPaperSheet from "./components/Planner/Print/PrintExamPaperSheet";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <App /> } />
        <Route path="t" element={ <Tafel/> } />
        <Route path="tafel" element={ <Tafel/> } >
          <Route path="a" element={ <Welcome/> }/>
        </Route>

        <Route path="p" element={ <Planner/> } />
        <Route path="planner/*" element={ <Planner/> }/>

        <Route path="pr" element={ <PrintExamPaperSheet/> } />
        <Route path="print" element={ <PrintExamPaperSheet/> } />

        <Route path="t" element={ <Timer/> } />
        <Route path="timer" element={ <Timer/> } />

        <Route path="*" element={ <div><h1>404 ERROR!</h1></div> }/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
