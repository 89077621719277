import {Fragment} from "react";
import {useNavigate} from "react-router-dom";
import BackButton from "../../UI/Controls/BackButtion";
import ConfirmButton from "../../UI/Controls/ConfirmButton";
import ControlBoxJustified from "../../UI/Controls/ControlBox/ControlBoxJustified";

const PrintControlBox = props => {
  const navigate = useNavigate();

  const leftButtons =
    <BackButton
      onClick = { () => navigate( -1 ) }
      text = "< zurück zum Planen"
    />;

  const rightButtons = (
    <Fragment>
      {/* Print for Examiners */}
      <ConfirmButton
        text = "Prüfungsplan"
        onClick = { () => navigate( "/planner/print/plan" ) }
      />
      {/* Print for Participants */}
      <ConfirmButton
        text = "Mündlich ( PR )"
        onClick = { () => navigate( "/planner/print/examiners" ) }
      />
      {/* Print Normal ExamPlan */}
      <ConfirmButton
        text = "Mündlich ( TN )"
        onClick = { () => navigate( "/planner/print/participants" ) }
      />
    </Fragment>
  );

  return(
    <ControlBoxJustified
      left =  { leftButtons }
      right = { rightButtons }
    />
  );
}

export default PrintControlBox;